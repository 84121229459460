// analytics/config.js

/**
 * HumanBetter Analytics Configuration
 * Handles both landing page (www.tryhumanbetter.com) and app (app.tryhumanbetter.com) tracking
 */

// Analytics Events
export const ANALYTICS_EVENTS = {
  // Landing Page Events
  LANDING_PAGE_VIEW: 'landing_page_view',
  SIGNUP_START: 'signup_start',
  SIGNUP_COMPLETE: 'signup_complete',
  LANDING_CTA_CLICK: 'landing_cta_click',
  FEATURE_SECTION_VIEW: 'feature_section_view',
  PRICING_VIEW: 'pricing_view',
  CONTACT_FORM_SUBMIT: 'contact_form_submit',
  APP_REDIRECT: 'app_redirect',
  SCROLL_DEPTH: 'scroll_depth',
  
  // Shared Events
  ERROR: 'error',
  BUTTON_CLICK: 'button_click'
};

/**
 * Initialize analytics for the landing page with cross-domain tracking
 * @param {string} measurementId - Google Analytics measurement ID
 */
export const initializeLandingAnalytics = (measurementId) => {
  // Load Google Analytics
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  window.gtag = gtag;
  gtag('js', new Date());

  // Configure cross-domain tracking
  gtag('config', measurementId, {
    page_path: window.location.pathname,
    site_type: 'landing',
    linker: {
      domains: ['tryhumanbetter.com', 'www.tryhumanbetter.com', 'app.tryhumanbetter.com'],
      accept_incoming: true,
      decorate_forms: true
    },
    cookie_flags: 'SameSite=None;Secure'  // Required for cross-domain tracking
  });

  // Enable automatic link decoration for cross-domain tracking
  gtag('set', 'linker', {
    domains: ['tryhumanbetter.com', 'www.tryhumanbetter.com', 'app.tryhumanbetter.com']
  });
};

/**
 * Track cross-domain navigation
 * @param {string} destination - Destination domain
 * @param {string} linkType - Type of link that was clicked
 */
export const trackCrossDomainNavigation = (destination, linkType = 'default') => {
  trackEvent('cross_domain_navigation', {
    destination_domain: destination,
    link_type: linkType,
    source_page: window.location.pathname,
    referrer: document.referrer
  });
};

// Rest of your existing tracking functions...
export const trackEvent = (eventName, eventParams = {}) => {
  if (window.gtag) {
    const baseParams = {
      site: window.location.hostname,
      timestamp: new Date().toISOString(),
      page_path: window.location.pathname,
      ...eventParams
    };

    window.gtag('event', eventName, baseParams);
  }
};

export const setUserProperties = (properties) => {
  if (window.gtag) {
    window.gtag('set', 'user_properties', properties);
  }
};

export const trackPageView = (section = '') => {
  trackEvent(ANALYTICS_EVENTS.LANDING_PAGE_VIEW, {
    section,
    referrer: document.referrer,
    screen_size: `${window.innerWidth}x${window.innerHeight}`
  });
};