import React, { useState, useCallback, useEffect } from 'react';
import { Heart, Calendar, Users, Gift, RefreshCw, Coffee, Utensils, Film, Music, ShoppingBag } from 'lucide-react';

const ALL_ACTIONS = [
  // Words of Affirmation
  {
    emoji: '💭',
    category: 'Words of Affirmation',
    title: "Send a Quick, Kind Text",
    description: "Brighten someone's day with a thoughtful message that shows you care."
  },
  {
    emoji: '💭',
    category: 'Words of Affirmation',
    title: "Leave a Sticky Note",
    description: "Place an unexpected compliment where they'll find it later."
  },
  {
    emoji: '💭',
    category: 'Words of Affirmation',
    title: "Share Daily Motivation",
    description: "Send an inspiring quote or message to encourage them."
  },
  // Quality Time
  {
    emoji: '⌛',
    category: 'Quality Time',
    title: "Coffee Moment",
    description: "Take a brief coffee break together to connect and catch up."
  },
  {
    emoji: '⌛',
    category: 'Quality Time',
    title: "Quick Video Call",
    description: "Schedule a short video chat just to see their smile."
  },
  {
    emoji: '⌛',
    category: 'Quality Time',
    title: "Lunch Walk Together",
    description: "Turn lunch into quality time with a short walk together."
  },
  // Gifts
  {
    emoji: '🎁',
    category: 'Thoughtful Gifts',
    title: "Surprise Treat",
    description: "Pick up their favorite candy or snack as a small surprise."
  },
  {
    emoji: '🤝',
    category: 'Acts of Service',
    title: "Helpful Gesture",
    description: "Take care of a small task like charging their device or refilling their water."
  },
  {
    emoji: '💝',
    category: 'Physical Touch',
    title: "Moment of Connection",
    description: "Share a quick hug or high five to show you care."
  }
];

const LandingPage = () => {
  const [displayedActions, setDisplayedActions] = useState(() => {
    const shuffled = [...ALL_ACTIONS].sort(() => Math.random() - 0.5);
    return shuffled.slice(0, 3);
  });

  const [currentWord, setCurrentWord] = useState('human');
  const [isSpinning, setIsSpinning] = useState(false);
  const words = ['human', 'father', 'mother', 'partner', 'brother', 'sister', 'friend'];
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isHovered, setIsHovered] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const WAITLIST_ENDPOINT = process.env.NODE_ENV === 'development'
    ? 'http://localhost:8889/.netlify/functions/add-to-waitlist'
    : '/.netlify/functions/add-to-waitlist';

  useEffect(() => {
    let spinTimeout;
    let transitionTimeout;
    
    const transition = () => {
      setIsSpinning(true);
      
      // Rapid cycling effect
      let count = 0;
      const maxCount = 15; // Number of rapid cycles
      const interval = setInterval(() => {
        count++;
        if (count >= maxCount) {
          clearInterval(interval);
          setIsSpinning(false);
          
          // Select next word (excluding current)
          const availableWords = words.filter(w => w !== currentWord);
          const nextWord = availableWords[Math.floor(Math.random() * availableWords.length)];
          setCurrentWord(nextWord);
          
          // Schedule next transition
          transitionTimeout = setTimeout(transition, 2000);
        } else {
          // During spinning, rapidly cycle through words
          setCurrentWord(words[Math.floor(Math.random() * words.length)]);
        }
      }, 50); // Speed of cycling (milliseconds)
    };

    // Start the first transition after initial delay
    spinTimeout = setTimeout(transition, 2000);

    return () => {
      clearTimeout(spinTimeout);
      clearTimeout(transitionTimeout);
    };
  }, []);

  const cycleActions = useCallback(() => {
    setDisplayedActions(prev => {
      const remainingActions = ALL_ACTIONS.filter(
        action => !prev.includes(action)
      );
      const shuffled = [...remainingActions].sort(() => Math.random() - 0.5);
      return shuffled.slice(0, 3);
    });
  }, []);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError(null);
    
    if (!validateEmail(email)) {
      setIsEmailValid(false);
      return;
    }

    setIsSubmitting(true);

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000);

      const response = await fetch(WAITLIST_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error('Failed to submit email');
      }

      const data = await response.json();
      
      if (data.status === 'success') {
        setSubmitSuccess(true);
        setEmail('');
      } else if (data.status === 'already_registered') {
        setSubmitError('This email is already registered');
      } else {
        throw new Error(data.message || 'Failed to submit email');
      }
    } catch (error) {
      console.error('Error submitting email:', error);
      setSubmitError(error.message === 'The operation was aborted.' 
        ? 'Request timed out. Please try again.' 
        : 'Failed to submit email. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const features = [
    {
      icon: Heart,
      title: "AI-Powered Suggestions",
      description: "Personalized gift and activity recommendations based on interests and relationship dynamics.",
      color: "bg-pink-500"
    },
    {
      icon: Calendar,
      title: "Smart Reminders",
      description: "Never miss important dates with intelligent notifications for birthdays, anniversaries, and special moments.",
      color: "bg-blue-500"
    },
    {
      icon: Users,
      title: "Relationship Insights",
      description: "Track and improve your connections with detailed relationship analytics and health metrics.",
      color: "bg-purple-500"
    },
    {
      icon: Gift,
      title: "Budget-Aware",
      description: "Get thoughtful suggestions that respect your budget while maximizing meaningful impact.",
      color: "bg-green-500"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      {/* Hero Section */}
      <div className="container mx-auto px-4 pt-20 pb-12 text-center">
        <h1 className="text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-indigo-600">
          Build Better Relationships
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-12">
          Never miss a moment with the people who matter most. HumanBetter helps you nurture 
          meaningful connections through personalized reminders, gift suggestions, and thoughtful planning.
        </p>

        {/* Email Signup */}
        <form onSubmit={handleSubmit} className="max-w-md mx-auto mb-16">
          <div className="relative">
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setIsEmailValid(true);
                setSubmitError(null);
              }}
              placeholder="Enter your email"
              disabled={isSubmitting || submitSuccess}
              className={`w-full px-6 py-3 rounded-full border ${
                !isEmailValid || submitError ? 'border-red-500' : 
                submitSuccess ? 'border-green-500' : 'border-gray-300'
              } focus:outline-none focus:ring-2 focus:ring-purple-500 pr-36`}
            />
            <button
              type="submit"
              disabled={isSubmitting || submitSuccess}
              className={`absolute right-1 top-1 px-6 py-2 rounded-full transition-colors duration-200 ${
                isSubmitting || submitSuccess 
                  ? 'bg-gray-400 cursor-not-allowed' 
                  : 'bg-purple-600 hover:bg-purple-700'
              } text-white flex items-center`}
            >
              {isSubmitting ? (
                <>
                  <RefreshCw className="animate-spin -ml-1 mr-2 h-4 w-4" />
                  Joining...
                </>
              ) : submitSuccess ? (
                'Joined! ✓'
              ) : (
                'Join Waitlist →'
              )}
            </button>
          </div>
          {!isEmailValid && (
            <p className="text-red-500 text-sm mt-2">Please enter a valid email address</p>
          )}
          {submitError && (
            <p className="text-red-500 text-sm mt-2">{submitError}</p>
          )}
          {submitSuccess && (
            <p className="text-green-500 text-sm mt-2">
              Successfully joined the waitlist! We'll be in touch soon.
            </p>
          )}
        </form>

        {/* New Headline */}
        <div className="relative mb-20">
          <div className="absolute inset-0 bg-gradient-to-r from-purple-400/10 to-indigo-400/10 transform -skew-y-2"></div>
          <h2 className="relative text-3xl font-semibold text-gray-800 py-8 flex items-center justify-center">
            We're here to help you be a better
            <div className="relative inline-block w-[140px] h-[40px] ml-2">
              {words.map(word => (
                <span
                  key={word}
                  className={`absolute left-0 top-0 transition-all duration-75 transform ${
                    isSpinning ? 'blur-sm' : ''
                  } ${
                    currentWord === word ? 'opacity-100 translate-y-0' : 'opacity-0'
                  }`}
                >
                  {word}
                </span>
              ))}
            </div>
          </h2>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
          {features.map((feature, index) => (
            <div
              key={index}
              className="relative p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              onMouseEnter={() => setIsHovered(index)}
              onMouseLeave={() => setIsHovered(null)}
            >
              <div className={`w-12 h-12 mx-auto mb-4 rounded-full flex items-center justify-center ${feature.color} bg-opacity-10`}>
                <feature.icon
                  size={24}
                  className={`${feature.color.replace('bg-', 'text-').replace('-500', '-600')}`}
                />
              </div>
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-600 text-sm">{feature.description}</p>
              {isHovered === index && (
                <div className="absolute inset-0 border-2 border-purple-500 rounded-xl animate-pulse pointer-events-none"></div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Action Examples Section */}
      <div className="bg-white py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-semibold text-center mb-12">
            See HumanBetter in Action
          </h2>
          
          <div className="max-w-4xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {displayedActions.map((action, index) => (
                <div
                  key={index}
                  className="bg-gradient-to-br from-purple-50 to-indigo-50 p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300"
                >
                  <div className="text-2xl mb-3">
                    {action.emoji}
                  </div>
                  <div className="text-xs text-purple-600 font-medium mb-1">
                    {action.category}
                  </div>
                  <h3 className="font-medium text-lg mb-2">{action.title}</h3>
                  <p className="text-sm text-gray-600">{action.description}</p>
                </div>
              ))}
            </div>
            
            <div className="text-center mt-8">
              <button
                onClick={cycleActions}
                className="inline-flex items-center px-6 py-3 bg-white border border-purple-200 rounded-full text-purple-600 hover:bg-purple-50 transition-colors duration-200"
              >
                <RefreshCw size={18} className="mr-2" />
                Show More Examples
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;