import React, { useEffect } from 'react';
import { Heart, Clock, Gift, MessageCircle, Lightbulb } from 'lucide-react';
import { trackEvent, ANALYTICS_EVENTS } from '../analytics/config';

const AboutPage = () => {
  useEffect(() => {
    trackEvent(ANALYTICS_EVENTS.LANDING_PAGE_VIEW, {
      section: 'about',
      page_name: 'About Us'
    });
  }, []);

  const handleSectionView = (sectionName) => {
    trackEvent(ANALYTICS_EVENTS.FEATURE_SECTION_VIEW, {
      section_name: sectionName,
      page: 'about'
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            handleSectionView(entry.target.dataset.section);
          }
        });
      },
      { threshold: 0.5 }
    );

    document.querySelectorAll('[data-section]').forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">About HumanBetter</h1>
          <p className="text-xl text-gray-600">
            We believe in the power of meaningful connections and thoughtful moments.
          </p>
        </div>

        <div className="space-y-16">
          {/* Mission Section */}
          <section data-section="mission">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Our Mission</h2>
            <p className="text-gray-600 leading-relaxed">
              HumanBetter was born from a simple observation: in our busy digital world, 
              maintaining meaningful relationships has become increasingly challenging. 
              We're here to change that by helping you create and nurture deeper connections 
              with the people who matter most in your life.
            </p>
          </section>

          {/* Values Section */}
          <section data-section="values">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Our Values</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="flex items-start">
                <Heart className="text-purple-600 mr-4 flex-shrink-0" />
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">Meaningful Connections</h3>
                  <p className="text-gray-600">
                    We prioritize quality over quantity, helping you build deeper, more meaningful relationships.
                  </p>
                </div>
              </div>
              <div className="flex items-start">
                <Clock className="text-purple-600 mr-4 flex-shrink-0" />
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">Timely Reminders</h3>
                  <p className="text-gray-600">
                    Never miss important moments with intelligent, personalized notifications.
                  </p>
                </div>
              </div>
              <div className="flex items-start">
                <Gift className="text-purple-600 mr-4 flex-shrink-0" />
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">Thoughtful Actions</h3>
                  <p className="text-gray-600">
                    Turn intentions into actions with personalized suggestions and reminders.
                  </p>
                </div>
              </div>
              <div className="flex items-start">
                <MessageCircle className="text-purple-600 mr-4 flex-shrink-0" />
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">Open Communication</h3>
                  <p className="text-gray-600">
                    We believe in transparent, honest communication with our users.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Future Vision Section */}
          <section data-section="vision">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Our Vision</h2>
            <div className="flex items-start">
              <Lightbulb className="text-purple-600 mr-4 flex-shrink-0 mt-1" />
              <p className="text-gray-600 leading-relaxed">
                We envision a world where technology enhances rather than replaces human 
                connection. Where everyone has the tools and support they need to build 
                and maintain meaningful relationships. Where no important moment goes 
                uncelebrated, and no connection is left to fade away unintentionally.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;